import { useState } from 'react';

const SliderComponent = ({
	testimonialTitle,
	testimonialSubTitle,
	testimonials,
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleScrollLeft = () => {
		const isFirstSlide = currentIndex === 0;
		const newIndex = isFirstSlide ? testimonials.length - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
	};

	const handleScrollRight = () => {
		const isLastSlide = currentIndex === testimonials.length - 1;
		const newIndex = isLastSlide ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
	};

	return (
		<article className='slider-col-component'>
			<header>
				<h2>{testimonialTitle}</h2>
				<p>{testimonialSubTitle}</p>
			</header>

			<div className='testimonial-wrapper scroll-flex-display'>
				<figure className='scroll-buttons'>
					<i
						onClick={handleScrollLeft}
						className='fa-solid fa-chevron-left left-arrow'
					></i>
					<i
						onClick={handleScrollRight}
						className='fa-solid fa-chevron-right right-arrow'
					></i>
				</figure>
				<section key={testimonials[currentIndex].id}>
					<h3>
						<blockquote>
							<p>{testimonials[currentIndex].testimony}</p>
						</blockquote>
					</h3>

					<section className='company-name flex-display'>
						<h4>- {testimonials[currentIndex].clientName}</h4>
						<img
							src={testimonials[currentIndex].companyLogo}
							alt='company logo'
						/>
					</section>
				</section>
			</div>
		</article>
	);
};

export default SliderComponent;
