const TwoColsComponentReverse = ({
	title,
	spanTitle,
	subTitle,
	img,
	alt,
	lists,
}) => {
	return (
		<article className='cols-2'>
			<section className='grid-display'>
				<header className='order2'>
					<div>
						<h2>
							{title} <span>{spanTitle}</span>
						</h2>
						<p>{subTitle}</p>
					</div>
					<section className='grid-display'>
						{lists.map((list) => (
							<div
								key={list.key}
								className=''
							>
								<h4>{list.title}</h4>
								<p>{list.content}</p>
							</div>
						))}
					</section>
				</header>
				<figure className='two-cols-img order1'>
					<img
						src={img}
						alt={alt}
					/>
				</figure>
			</section>
		</article>
	);
};

export default TwoColsComponentReverse;
