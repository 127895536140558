const CardComponents = ({ cardTitle, cardSubTitle, cardContents }) => {
	return (
		<article className='cards'>
			<header>
				<h2>{cardTitle}</h2>
				<p>{cardSubTitle}</p>
			</header>
			<div className='cards-wrapper flex-display'>
				{cardContents.map((cardContent) => (
					<section
						key={cardContent.id}
						className='grid-display'
					>
						<header>
							<h3>{cardContent.title}</h3>
							<p>{cardContent.subTitle}</p>
						</header>
						<figure>
							<img
								src={cardContent.img}
								alt={cardContent.title}
							/>
						</figure>
					</section>
				))}
			</div>
		</article>
	);
};

export default CardComponents;
