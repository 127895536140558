import { Link } from 'react-router-dom/dist';
import trustBadge from './img/trust-badge.png';
import heroImg from './img/hero-img.jpeg';
import key from './img/unlocking-key.png';

const Hero = ({ logos }) => {
	return (
		<article className='hero-section'>
			<section className='grid-display'>
				<header>
					<div className='main-header-content'>
						<h1>
							<div className='unlock'>
								<span>Unlock</span>
								<img
									src={key}
									width={100}
									height={100}
									alt='unlocking key'
								/>
							</div>
							Next Level Innovations <br />
							For Your Business.
						</h1>
						<p className='sub-title'>
							We deliver seamless, innovative and tailored solutions for your
							business needs.
						</p>
						<section>
							<ul className='hero-service-list'>
								<div className='hero-service-list-display'>
									<img
										src={trustBadge}
										alt='trust worthy badge'
									/>
									<li key='1'>Business Consultancy</li>
								</div>
								<div className='hero-service-list-display'>
									<img
										src={trustBadge}
										alt='trust worthy badge'
									/>
									<li key='2'>I.T Procurement</li>
								</div>
								<div className='hero-service-list-display'>
									<img
										src={trustBadge}
										alt='trust worthy badge'
									/>
									<li key='3'>Project Management</li>
								</div>
							</ul>
						</section>
						<div className='cta-wrapper'>
							<Link
								to='/contact'
								className='cta'
							>
								Schedule a meeting
							</Link>
						</div>
					</div>

					<section className='brands-wrapper'>
						{logos.map((logo) => (
							<div key={logo.id}>
								<img
									src={logo.img}
									width={100}
									height={100}
									alt='brand logo'
								/>
							</div>
						))}
					</section>
				</header>

				<figure className='hero-img'>
					<img
						src={heroImg}
						alt='man focused planning and strategizing'
					/>
				</figure>
			</section>
		</article>
	);
};

export default Hero;
