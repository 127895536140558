const navbar = document.querySelector('.sticky-nav');
const sticky = navbar?.offsetTop;

window.addEventListener('scroll', () => {
	if (window.scrollY >= sticky) {
		navbar?.classList.add('sticky');
	} else {
		navbar?.classList.remove('sticky');
	}
});
