import { useState } from 'react';
import { Link } from 'react-router-dom';

const StickyNav = ({ logo, navList }) => {
	const [visible, setVisible] = useState('hidden');

	const handleShowMobileNav = () => {
		setVisible('slide-in');
	};

	const handleHideMoileNav = () => {
		setVisible('hidden');
	};

	return (
		<nav className='sticky-nav flex-display'>
			<figure>
				<Link to='/'>
					<img
						src={logo}
						alt='company logo'
					/>
				</Link>
			</figure>

			<ul className='nav-link'>
				{navList.map((list) => (
					<li key={list.id}>
						<Link to={`/` + list.title}>{list.title}</Link>
					</li>
				))}
			</ul>
			<i
				onClick={handleShowMobileNav}
				className='fa-solid fa-bars fa-2x'
			></i>

			<ul className={'mobile-nav ' + visible}>
				<i
					onClick={handleHideMoileNav}
					className='fa-solid fa-xmark fa-2x'
				></i>

				{navList.map((list) => (
					<li
						className='mobile-link'
						key={list.id}
					>
						<Link to={`/` + list.title}>{list.title}</Link>
					</li>
				))}
				<li>
					<Link to='/#faq'>FAQ</Link>
				</li>
				<li>
					<Link
						to='/contact'
						className='cta'
					>
						Schedule a meeting
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default StickyNav;
